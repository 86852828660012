export const config = {
  routes: {
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  addwish: {
    productPage: [
      'k6005a97fcb48a84544958d1d',
      'k6005a992cb48a84544959786',
      'k6005a9decb48a8454495c5e9'
    ],
    categoryPage: 'k6005a9e5cb48a8454495c9be',
    cart: 'k6005aa05cb48a8454495e02c'
  },
  settings: {
    storeName: 'MFF Shopen',
    siteContentId: 59
  }
};
