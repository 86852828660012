import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 0;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

const FooterContent = () => {
  const item = useSiteContent(['Footer']);

  if (!item) return null;

  return <FooterContentWrapper>{item.html}</FooterContentWrapper>;
};

export default FooterContent;
