import React from 'react';
import { styled } from 'linaria/react';
import NewsletterField from '../../Newsletter/NewsletterField';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth, { MaxWidthWrapper, Inner } from '../MaxWidth';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  color: ${theme.colors.white};
  ${MaxWidthWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }
  .image-wrapper {
    width: 100%;
    [data-flight-image-container] {
      ${theme.below.lg} {
        padding-bottom: 50% !important;
      }
      ${theme.below.md} {
        padding-bottom: 80% !important;
      }
      ${theme.below.sm} {
        padding-bottom: 110% !important;
      }
    }
  }
  .text-wrapper {
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem ${theme.general.pagePadding.sm};
    ${theme.above.sm} {
      padding: 4rem ${theme.general.pagePadding.md};
    }
    display: flex;
    flex-direction: column;
    p {
      margin: 1em 0;
    }
    > * {
      width: 100%;
      max-width: 700px;
    }
    .newsletter-wrapper {
      border: 2px solid ${theme.colors.font};
      > p {
        text-align: center;
      }
      input {
      }
      button {
        background: ${theme.colors.white};
        color: ${theme.colors.font};
        text-transform: uppercase;
        opacity: 1 !important;
        border-left: 2px solid ${theme.colors.font};
      }
    }
  }
  //TRANSPARENT TYPE
  &.transparent {
    ${MaxWidthWrapper} {
      width: 100%;
    }
    .text-wrapper {
      background: none;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      .text {
        h5 {
          margin: 2rem 0;
        }
        > h2,
        > h3,
        > h4,
        > h5,
        > p {
          color: ${theme.colors.white};
        }
      }
      .newsletter-wrapper {
        background: none;
        border: none;
        input {
          background: ${theme.colors.white};
          border: none;
          margin-right: 1rem;
          color: ${theme.colors.primary};
        }
        button {
          background: ${theme.colors.white};
          color: ${theme.colors.primary};
          font-weight: ${theme.weights.bold};
          text-transform: uppercase;
          opacity: 1 !important;
          border: none;
          margin-left: 10px;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  const item = useSiteContent(['Newsletter']);

  if (!item) return null;

  return (
    <NewsletterBoxWrapper className={true ? 'transparent' : 'standard'}>
      {item.image && (
        <div className="image-wrapper">
          <Image
            aspect={'3:1'}
            sizes={[1, 1, 1 / 2, 1 / 2]}
            src={item.image}
            alt={item.image}
            crop={true}
            cover
          />
        </div>
      )}
      <MaxWidth>
        <Inner>
          <div className="text-wrapper">
            <div className="text">{item.html}</div>
            <NewsletterField />
          </div>
        </Inner>
      </MaxWidth>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
