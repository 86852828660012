import { css } from 'linaria';

import bodyReg from './roboto-regular.woff2';
import bodyLight from './roboto-light.woff2';
import headerBoldTTF from './horatio-bold.ttf';
import headerLight from './horatio-light.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      @font-face {
        font-family: 'roboto';
        src: url(${bodyReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'roboto';
        src: url(${bodyLight}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'horatio';
        src: url(${headerBoldTTF}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'horatio';
        src: url(${headerLight}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
